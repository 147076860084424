define("@zestia/ember-select-box/utils/shared/ready", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ready = ready;
  function ready(selectBox) {
    selectBox.isReady = true;
    (0, _runloop.scheduleOnce)('afterRender', selectBox, _ready, selectBox);
  }
  function _ready(selectBox) {
    var _selectBox$args$onRea, _selectBox$args;
    (_selectBox$args$onRea = (_selectBox$args = selectBox.args).onReady) === null || _selectBox$args$onRea === void 0 || _selectBox$args$onRea.call(_selectBox$args, selectBox.api);
  }
});