define("@ember/polyfills/index", ["exports", "@ember/polyfills/lib/assign"], function (_exports, _assign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "assign", {
    enumerable: true,
    get: function get() {
      return _assign.assign;
    }
  });
  _exports.hasPropertyAccessors = void 0;
  var hasPropertyAccessors = _exports.hasPropertyAccessors = true;
});