define("@zestia/ember-select-box/utils/general/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getKeyName = getKeyName;
  _exports.keys = void 0;
  var keys = _exports.keys = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    27: 'escape',
    32: 'space',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down'
  };
  function getKeyName(e) {
    return keys[e.keyCode];
  }
});