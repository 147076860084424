define("@zestia/ember-select-box/utils/native-select-box/selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSelectedValue = getSelectedValue;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function getSelectedValue(selectBox) {
    var selectedValues = getSelectedValues(selectBox);
    if (selectBox.isMultiple) {
      return selectedValues;
    } else {
      return selectedValues[0];
    }
  }
  function getSelectedValues(selectBox) {
    var registered = getRegisteredSelectedValues(selectBox);
    var unregistered = getUnregisteredSelectedValues(selectBox);
    if (registered.length > 0) {
      return registered;
    } else {
      return unregistered;
    }
  }
  function getRegisteredSelectedValues(selectBox) {
    return selectBox.option.filter(function (option) {
      return option.element.selected;
    }).map(function (option) {
      return option.value;
    });
  }
  function getUnregisteredSelectedValues(selectBox) {
    return _toConsumableArray(selectBox.element.querySelectorAll('option:checked')).map(function (element) {
      return element.value;
    });
  }
});