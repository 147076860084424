define("@zestia/ember-select-box/utils/registration/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterElement = deregisterElement;
  _exports.registerElement = registerElement;
  function registerElement(component, element) {
    component.element = element;
  }
  function deregisterElement(component) {
    component.element = null;
  }
});