define("@zestia/ember-select-box/utils/select-box/option/select", ["exports", "@zestia/ember-select-box/utils/select-box/value"], function (_exports, _value) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._selectOption = _selectOption;
  _exports.selectOption = selectOption;
  _exports.selectedOption = selectedOption;
  function _selectOption(option) {
    var _option$args$_onSelec, _option$args;
    if (option.isDisabled) {
      return;
    }
    return (_option$args$_onSelec = (_option$args = option.args)._onSelect) === null || _option$args$_onSelec === void 0 ? void 0 : _option$args$_onSelec.call(_option$args, option);
  }
  function selectOption(selectBox, option) {
    selectedOption(option);
    return (0, _value.selectValue)(selectBox, option.value);
  }
  function selectedOption(option) {
    var _option$args$onSelect, _option$args2;
    (_option$args$onSelect = (_option$args2 = option.args).onSelect) === null || _option$args$onSelect === void 0 || _option$args$onSelect.call(_option$args2, option.value, option.args.selectBox.api);
  }
});