define("@zestia/ember-select-box/utils/registration/selected-options", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterSelectedOptions = deregisterSelectedOptions;
  _exports.registerSelectedOptions = registerSelectedOptions;
  function registerSelectedOptions(selectBox, selectedOptions) {
    (false && !(!selectBox.selectedOptions) && (0, _debug.assert)('select-box can only have 1 selected options container', !selectBox.selectedOptions));
    selectBox.selectedOptions = selectedOptions;
  }
  function deregisterSelectedOptions(selectBox) {
    selectBox.selectedOptions = null;
  }
});