define("@zestia/ember-select-box/utils/select-box/option/activate", ["exports", "@zestia/ember-select-box/utils/component/filter", "@zestia/ember-select-box/utils/component/scroll-into-view"], function (_exports, _filter, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._activateOption = _activateOption;
  _exports.activateNextOption = activateNextOption;
  _exports.activateOption = activateOption;
  _exports.activateOptionAtIndex = activateOptionAtIndex;
  _exports.activateOptionForKeyCode = activateOptionForKeyCode;
  _exports.activateOptionForValue = activateOptionForValue;
  _exports.activatePreviousOption = activatePreviousOption;
  _exports.deactivateOptions = deactivateOptions;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var fromCharCode = String.fromCharCode;
  function _activateOption(option) {
    var _option$args$_onActiv, _option$args;
    if (option.args.selectBox.activeOption === option) {
      return;
    }
    (_option$args$_onActiv = (_option$args = option.args)._onActivate) === null || _option$args$_onActiv === void 0 || _option$args$_onActiv.call(_option$args, option);
  }
  function activatedOption(option) {
    var _option$args$onActiva, _option$args2;
    (_option$args$onActiva = (_option$args2 = option.args).onActivate) === null || _option$args$onActiva === void 0 || _option$args$onActiva.call(_option$args2, option.value, option.args.selectBox.api);
  }
  function activateOption(selectBox, option, config) {
    activateOptionAtIndex(selectBox, option.index, config);
  }
  function deactivateOptions(selectBox) {
    _setActiveOptionIndex(selectBox, -1);
  }
  function focusOption(option) {
    option.element.focus();
  }
  function _setActiveOptionIndex(selectBox, index) {
    selectBox.activeOptionIndex = index;
  }
  function setActiveOptionIndex(selectBox, index) {
    var under = index < 0;
    var over = index > selectBox.option.length - 1;
    if (under || over) {
      return;
    }
    _setActiveOptionIndex(selectBox, index);
  }
  function activateOptionAtIndex(selectBox, index, config) {
    setActiveOptionIndex(selectBox, index);
    var option = selectBox.activeOption;
    if (!option) {
      return;
    }
    (0, _scrollIntoView.maybeScrollIntoView)(option, config);
    focusOption(option);
    activatedOption(option);
  }
  function activateNextOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex + 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }
  function activatePreviousOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex - 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }
  function activateOptionForValue(selectBox, value, config) {
    var option = selectBox.option.find(function (option) {
      return option.value === value;
    });
    if (!option) {
      return;
    }
    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }
  function activateOptionForKeyCode(selectBox, keyCode, config) {
    var char = fromCharCode(keyCode);
    if (!char) {
      return;
    }
    var option = optionForChar(selectBox, char);
    if (!option) {
      return;
    }
    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }
  function optionForChar(selectBox, char) {
    var _selectBox$charState;
    var prev = (_selectBox$charState = selectBox.charState) !== null && _selectBox$charState !== void 0 ? _selectBox$charState : {
      chars: '',
      index: 0
    };
    var prevChar = prev.chars.substring(prev.chars.length - 1);
    var repeatedChar = char === prevChar;
    var chars = "".concat(prev.chars).concat(char);
    var registeredOptions = selectBox.option;
    var options = (0, _filter.filterComponentsByTextContent)(registeredOptions, chars);
    var index = 0;
    var option = null;
    if (repeatedChar) {
      index = prev.index + 1;
      options = (0, _filter.filterComponentsByTextContent)(registeredOptions, prevChar);
      option = options[index];
    }
    if (!option) {
      index = 0;
      option = options[index];
    }
    if (prev.timer) {
      clearTimeout(prev.timer);
    }
    var timer = setTimeout(function () {
      return selectBox.charState = null;
    }, 1000);
    var next = {
      chars,
      index,
      timer
    };
    selectBox.charState = next;
    return option;
  }
});