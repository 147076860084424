define("@zestia/ember-select-box/utils/shared/id", ["exports", "@ember/object/internals"], function (_exports, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = id;
  function id(object) {
    return (0, _internals.guidFor)(object).replace('ember', 'select-box-el-');
  }
});