define("@zestia/ember-select-box/utils/registration/selected-option", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deregisterSelectedOption = deregisterSelectedOption;
  _exports.registerSelectedOption = registerSelectedOption;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function registerSelectedOption(selectBox, option) {
    selectBox.pendingSelectedOption.push(option);
    scheduleUpdateSelectedOptions(selectBox);
  }
  function deregisterSelectedOption(selectBox, option) {
    selectBox.pendingSelectedOption.splice(selectBox.pendingSelectedOption.indexOf(option), 1);
    scheduleUpdateSelectedOptions(selectBox);
  }
  function scheduleUpdateSelectedOptions(selectBox) {
    (0, _runloop.scheduleOnce)('afterRender', selectBox, updateSelectedOptions, selectBox);
  }
  function updateSelectedOptions(selectBox) {
    if (selectBox.isDestroying) {
      return;
    }
    setSelectedOptions(selectBox, selectBox.pendingSelectedOption);
  }
  function setSelectedOptions(selectBox, selectedOptions) {
    var elements = _toConsumableArray(selectBox.element.querySelectorAll('.select-box__selected-option'));
    var sort = function sort(a, b) {
      return elements.indexOf(a.element) - elements.indexOf(b.element);
    };
    selectBox.selectedOption = _toConsumableArray(selectedOptions).sort(sort);
  }
});