define("@zestia/ember-select-box/utils/general/collapse-whitespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = collapseWhitespace;
  function collapseWhitespace() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return string.replace(/[\t\r\n]/g, ' ').replace(/ +/g, ' ').replace(/^ /, '').replace(/ $/, '');
  }
});