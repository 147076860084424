define("@ember/object/promise-proxy-mixin", ["exports", "@ember/object", "@ember/object/mixin", "@ember/error"], function (_exports, _object, _mixin, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module @ember/object/promise-proxy-mixin
  */

  function tap(proxy, promise) {
    (0, _object.setProperties)(proxy, {
      isFulfilled: false,
      isRejected: false
    });
    return promise.then(function (value) {
      if (!proxy.isDestroyed && !proxy.isDestroying) {
        (0, _object.setProperties)(proxy, {
          content: value,
          isFulfilled: true
        });
      }
      return value;
    }, function (reason) {
      if (!proxy.isDestroyed && !proxy.isDestroying) {
        (0, _object.setProperties)(proxy, {
          reason,
          isRejected: true
        });
      }
      throw reason;
    }, 'Ember: PromiseProxy');
  }
  var PromiseProxyMixin = _mixin.default.create({
    /**
      If the proxied promise is rejected this will contain the reason
      provided.
         @property reason
      @default null
      @public
    */
    reason: null,
    /**
      Once the proxied promise has settled this will become `false`.
         @property isPending
      @default true
      @public
    */
    isPending: (0, _object.computed)('isSettled', function () {
      return !(0, _object.get)(this, 'isSettled');
    }).readOnly(),
    /**
      Once the proxied promise has settled this will become `true`.
         @property isSettled
      @default false
      @public
    */
    isSettled: (0, _object.computed)('isRejected', 'isFulfilled', function () {
      return (0, _object.get)(this, 'isRejected') || (0, _object.get)(this, 'isFulfilled');
    }).readOnly(),
    /**
      Will become `true` if the proxied promise is rejected.
         @property isRejected
      @default false
      @public
    */
    isRejected: false,
    /**
      Will become `true` if the proxied promise is fulfilled.
         @property isFulfilled
      @default false
      @public
    */
    isFulfilled: false,
    /**
      The promise whose fulfillment value is being proxied by this object.
         This property must be specified upon creation, and should not be
      changed once created.
         Example:
         ```javascript
      import ObjectProxy from '@ember/object/proxy';
      import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
         ObjectProxy.extend(PromiseProxyMixin).create({
        promise: <thenable>
      });
      ```
         @property promise
      @public
    */
    promise: (0, _object.computed)({
      get() {
        throw new _error.default("PromiseProxy's promise must be set");
      },
      set(_key, promise) {
        return tap(this, promise);
      }
    }),
    /**
      An alias to the proxied promise's `then`.
         See RSVP.Promise.then.
         @method then
      @param {Function} callback
      @return {RSVP.Promise}
      @public
    */
    then: promiseAlias('then'),
    /**
      An alias to the proxied promise's `catch`.
         See RSVP.Promise.catch.
         @method catch
      @param {Function} callback
      @return {RSVP.Promise}
      @since 1.3.0
      @public
    */
    catch: promiseAlias('catch'),
    /**
      An alias to the proxied promise's `finally`.
         See RSVP.Promise.finally.
         @method finally
      @param {Function} callback
      @return {RSVP.Promise}
      @since 1.3.0
      @public
    */
    finally: promiseAlias('finally')
  });
  function promiseAlias(name) {
    return function () {
      var promise = (0, _object.get)(this, 'promise'); // We need this cast because `Parameters` is deferred so that it is not
      // possible for TS to see it will always produce the right type. However,
      // since `AnyFn` has a rest type, it is allowed. See discussion on [this
      // issue](https://github.com/microsoft/TypeScript/issues/47615).

      return promise[name].apply(promise, arguments);
    };
  }
  var _default = _exports.default = PromiseProxyMixin;
});